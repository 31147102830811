// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-logo">
          LEVANTAM
        </div>
        <p style={{width: '90%'}}>
          The information presented on this web site is not a solicitation for investment. 
          Such investment is only offered on the basis of information and representations made 
          in the appropriate offering documentation. Past performance is not necessarily indicative 
          of future results. No representation is being made that any investor will or is likely to
           achieve similar results. Crypto trading is speculative, involves substantial risk and 
           is not suitable for all investors.
        </p>
        <a
          className="App-link"
          href="mailto:info@levantam.finance​"
          target="_blank"
          rel="noopener noreferrer"
        >
          info@levantam.finance​
        </a>
      </header>
    </div>
  );
}

export default App;
